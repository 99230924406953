import { useEffect, useMemo } from 'react';
import * as serumCmn from '@project-serum/common';
import * as web3 from '@solana/web3.js';
import { useReduxDispatch } from './useReduxHooks';
import { setConnection } from '../store/slices/appSlice';
import { loadVaultBalances, loadGlobalVaultData } from '../store/slices/vaultSlice';
import { loadVaultTokenYeilds } from '../store/slices/marketSlice';
import { getVaultProgram } from '../utils/programs';
import AsyncPolling from '../utils/polling';

export default async function useInitiateAppData(
  connection: web3.Connection,
  wallet: serumCmn.Wallet
) {
  const dispatch = useReduxDispatch();

  const { program, provider } = useMemo(
    () => getVaultProgram(connection, wallet),
    [connection, wallet]
  );

  useEffect(() => {
    if (provider.wallet) {
      dispatch(setConnection({ provider, program }));
      dispatch(loadVaultBalances({ program, provider }));
    }
  }, [dispatch, program, provider]);

  useEffect(() => {
    const asyncFunc = () => {
      dispatch(loadGlobalVaultData());
    };

    const polling = new AsyncPolling(asyncFunc, 30000); // half minute interval
    polling.startPolling();

    return () => polling.terminatePolling();
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(loadCoinsUSDPrice());
  // }, [dispatch]);

  useEffect(() => {
    const asyncFunc = () => {
      dispatch(loadVaultTokenYeilds());
    };

    const polling = new AsyncPolling(asyncFunc, 1000 * 60 * 5); // 5 minutes interval
    polling.startPolling();

    return () => polling.terminatePolling();
  }, [dispatch]);
}
