import classNames from 'classnames';
import styles from './DotsLoading.module.scss';

const DotsLoading = () => {
  return (
    <div className={styles.dots}>
      {[...new Array(3).keys()].map((id) => (
        <span key={id} className={classNames(styles.dot, styles[`dot_${id}`])} />
      ))}
    </div>
  );
};

export default DotsLoading;
