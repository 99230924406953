import { FC } from 'react';

const VaultIcon: FC<{ active: boolean }> = ({ active }) => {
  return (
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none">
      <path
        d="M2.636 6.1508L9 0L15.364 6.1508C16.6227 7.3673 17.4798 8.91723 17.8271 10.6046
          C18.1743 12.2919 17.9961 14.0409 17.3149 15.6303C16.6337 17.2197 15.4802 18.5783 14.0001 19.534
          C12.5201 20.4898 10.78 21 9 21C7.21998 21 5.47992 20.4898 3.99988 19.534
          C2.51984 18.5783 1.36629 17.2197 0.685099 15.6303C0.00390685 14.0409 -0.17433 12.2919 0.172928 10.6046
          C0.520187 8.91723 1.37734 7.3673 2.636 6.1508ZM9 2.73422L4.05 7.51742
          C3.39903 8.14501 2.88281 8.89075 2.531 9.71177C2.17919 10.5328 1.99874 11.4129 2 12.3016H16
          C16.0013 11.4129 15.8208 10.5328 15.469 9.71177C15.1172 8.89075 14.601 8.14501 13.95 7.51742L9 2.73326V2.73422Z"
        fill={active ? 'url(#vault_linear_paint)' : '#7D657E'}
      />
      <defs>
        <linearGradient
          id="vault_linear_paint"
          x1="18.6473"
          y1="-2.85715"
          x2="-4.77326"
          y2="-0.551416"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9774E2" />
          <stop offset="0.359375" stopColor="#CF6A80" />
          <stop offset="1" stopColor="#EF6D36" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default VaultIcon;
