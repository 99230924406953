import appReducer from './slices/appSlice';
import marketReducer from './slices/marketSlice';
import messageReducer from './slices/messageSlice';
import modalReducer from './slices/modalSlice';
import vaultReducer from './slices/vaultSlice';

const rootReducer = {
  app: appReducer,
  message: messageReducer,
  modal: modalReducer,
  vault: vaultReducer,
  market: marketReducer,
};

export default rootReducer;
