import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPriceFromCoinGecko, fetchYeildsFromTulip } from '../../utils/fetch';
import { CALUSD_SYMBOL, COLLATERAL_TOKENS } from '../../constants/token';
import { AllVaultTokens, CollateralTypes } from '../../types/unions';

type PricesState = {
  [key in AllVaultTokens]: {
    usd: number;
  };
};

type APYStates = {
  [key in CollateralTypes]: number;
};

export const loadCoinsUSDPrice = createAsyncThunk('market/loadCoinsUSDPrice', async () => {
  const prices: PricesState = await fetchPriceFromCoinGecko();

  return prices;
});

export const loadVaultTokenYeilds = createAsyncThunk('market/loadVaultTokenYeilds', async () => {
  const apys: APYStates = await fetchYeildsFromTulip();
  return apys;
});

interface VaultState {
  prices: PricesState;
  APYs: APYStates;
}

export const initialState: VaultState = {
  prices: COLLATERAL_TOKENS.reduce(
    (price, token) => {
      price[token] = {
        usd: 1,
      };
      return price;
    },
    {
      [CALUSD_SYMBOL]: {
        usd: 1,
      },
    } as PricesState
  ),
  APYs: COLLATERAL_TOKENS.reduce((apys, token) => {
    apys[token] = 0;
    return apys;
  }, {} as APYStates),
};

const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadCoinsUSDPrice.fulfilled, (state, action) => {
        state.prices = {
          ...state.prices,
          ...action.payload,
        };
      })
      .addCase(loadCoinsUSDPrice.rejected, (state, { error }) => {
        console.log(error);
      })
      .addCase(loadVaultTokenYeilds.fulfilled, (state, action) => {
        state.APYs = {
          ...state.APYs,
          ...action.payload,
        };
      })
      .addCase(loadVaultTokenYeilds.rejected, (state, { error }) => {
        console.log(error);
      });
  },
});

export default marketSlice.reducer;
