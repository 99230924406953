import React from 'react';
import { PublicKey } from '@solana/web3.js';
import { CoinUSDC, CoinUSDT } from '../assets/svgs';
import { CALUSD_ADDRESS, CALUSD_VAULT_ADDRESS, VAULT_ADDRESSES } from '../configs';
import { CALUSD_DECIMAL, COLLATERAL_INDEX, LOAN_TOKENS } from '../constants/token';
import { CollateralTypes, VaultTypes } from '../types/unions';

const icons = {
  USDC: CoinUSDC,
  USDT: CoinUSDT,
};

interface TokenAddressStates {
  vault: VaultTypes;
  index: number;
  mintAddress: PublicKey;
  mintDecimals: number;
  vaultAddress: PublicKey;
  vaultTokenAccount: PublicKey;
  CoinLogo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const CollateralTokens = LOAN_TOKENS.reduce((accm, type) => {
  const vault = VAULT_ADDRESSES[type];

  vault.collaterals.forEach(({ symbol, mint, decimal, vaultTokenAccount }) => {
    accm[symbol as CollateralTypes] = {
      vault: type,
      index: COLLATERAL_INDEX[symbol as CollateralTypes],
      mintAddress: new PublicKey(mint),
      mintDecimals: decimal,
      vaultAddress: new PublicKey(vault.vault),
      vaultTokenAccount: new PublicKey(vaultTokenAccount),
      CoinLogo: icons[symbol as CollateralTypes],
    };
  });

  return accm;
}, {} as { [key in CollateralTypes]: TokenAddressStates });

export const VaultTokens = {
  calUSD: {
    symbol: 'calUSD',
    mint: CALUSD_ADDRESS,
    decimals: CALUSD_DECIMAL,
    vault: CALUSD_VAULT_ADDRESS,
  },
};
