import { PublicKey } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import VAULTS_DEV from './vaults.dev.json';
import VAULTS_PROD from './vaults.prod.json';

export const SHOW_TOOLS = process.env.REACT_APP_SHOW_TOOLS === 'true';
export const HIDE_CONVERT = process.env.REACT_APP_HIDE_CONVERT === 'true';
export const MAX_LTV_RATIO = 0.5;
export const CLUSTER = process.env.REACT_APP_CLUSTER! as WalletAdapterNetwork;
export const COINGECKO_SERVICE = 'https://api.coingecko.com/api/v3';
export const TULIP_SERVICE = 'https://api.tulip.garden/v2';

export const COMMUNITY_DISCORD = 'https://discord.gg/Me2zTTdQpu';
export const COMMUNITY_TWITTER = 'https://twitter.com/CalciferFi';
export const COMMUNITY_MEDIUM = 'https://medium.com/@CalciferFinance';
export const COMMUNITY_GITBOOK = 'https://calcifer-finance.gitbook.io/calcifer-finance';

export const CLUSTER_API_URL = {
  devnet: 'https://api.devnet.solana.com',
  testnet: 'https://api.testnet.solana.com',
  'mainnet-beta': 'https://solana-api.projectserum.com',
  // 'mainnet-beta': 'https://api.mainnet-beta.solana.com',
}[CLUSTER];

export const IS_MAINNET = CLUSTER === 'mainnet-beta';

export const VAULT_ADDRESSES = IS_MAINNET ? VAULTS_PROD : VAULTS_DEV;

export const VAULT_PROGRAM_ID = new PublicKey(process.env.REACT_APP_VAULT_PROGRAM_ID!);
export const VAULT_TULIP_PROGRAM_ID = new PublicKey(process.env.REACT_APP_TEST_VAULT_PROGRAM_ID!);
export const CALUSD_ADDRESS = new PublicKey(process.env.REACT_APP_TOKEN_CALUSD!);
export const CALUSD_VAULT_ADDRESS = new PublicKey(process.env.REACT_APP_CALUSD_VAULT_ADDRESS!);

// Only used for development
export const WITHDRAW_SHARE_MINT = new PublicKey('5Yix8Z6xyUHiwcjgjVcgUGgMCFgrUyEwYCXgf6QCR1Q9');
