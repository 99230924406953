export enum VaultActionTypes {
  DEPOSIT = 'Deposit',
  BORROW = 'Borrow',
  WITHDRAW = 'Withdraw',
  REPAY = 'Repay',
  LIQUIDATE = 'Liquidate',
  NONE = 'None',
}

export enum ActionStatus {
  NORMAL,
  CONFIRMING,
  SUCCESS,
  FAILED,
}

export enum ModalTypes {
  NONE = 'None',
  VAULT_ACTIONS = 'VaultActions',
  STAKE_UNSTAKE = 'StakeUnstake',
  CONVERT_EXIT = 'ConvertExit',
  MATURITY_CALCULATOR = 'MaturityCalculator',
}
