import { PublicKey } from '@solana/web3.js';
import { VAULT_TULIP_PROGRAM_ID, VAULT_PROGRAM_ID } from '../configs';
import { CollateralTypes } from '../types/unions';

export async function getVaultPDA(vault: PublicKey) {
  return await PublicKey.findProgramAddress(
    [vault.toBuffer(), Buffer.from('pda')],
    VAULT_PROGRAM_ID
  );
}

export async function getUserVaultStatePDA(
  wallet: PublicKey,
  vault: PublicKey
): Promise<[PublicKey, number]> {
  return await PublicKey.findProgramAddress(
    [wallet.toBytes(), vault.toBytes(), Buffer.from('user')],
    VAULT_PROGRAM_ID
  );
}

export async function getUserTransmuterPDA(
  wallet: PublicKey,
  vault: PublicKey,
  collIndex: number
): Promise<[PublicKey, number]> {
  return await PublicKey.findProgramAddress(
    [wallet.toBytes(), vault.toBytes(), Buffer.from([collIndex]), Buffer.from('user_transmuter')],
    VAULT_PROGRAM_ID
  );
}

export async function getLoanMintPDA(loanMint: PublicKey): Promise<[PublicKey, number]> {
  return await PublicKey.findProgramAddress(
    [loanMint.toBytes(), Buffer.from('mint')],
    VAULT_PROGRAM_ID
  );
}

export async function getTestTulipVault(symbol: CollateralTypes): Promise<[PublicKey, number]> {
  return await PublicKey.findProgramAddress(
    [Buffer.from(symbol), Buffer.from('vault')],
    VAULT_TULIP_PROGRAM_ID
  );
}

export async function getTestTulipVaultPDA(vault: PublicKey): Promise<[PublicKey, number]> {
  return await PublicKey.findProgramAddress([vault.toBuffer()], VAULT_TULIP_PROGRAM_ID);
}

export async function getTestTulipShareMintPDA(vault: PublicKey): Promise<[PublicKey, number]> {
  return await PublicKey.findProgramAddress(
    [vault.toBuffer(), Buffer.from('shares')],
    VAULT_TULIP_PROGRAM_ID
  );
}

export async function getTestTulipVaultUnderlyingAccount(
  vault: PublicKey
): Promise<[PublicKey, number]> {
  return await PublicKey.findProgramAddress(
    [vault.toBuffer(), Buffer.from('token_vault')],
    VAULT_TULIP_PROGRAM_ID
  );
}
