import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import ConnectButton from '../Wallet/ConnectButton';
import { useReduxSelector, useReduxDispatch } from '../../hooks/useReduxHooks';
import { setAppLaunched } from '../../store/slices/appSlice';
import { Discord, LogoWithFont, Twitter } from '../../assets/svgs';
import styles from './Navigation.module.scss';
import NavLinks from './NavLinks';
import { COMMUNITY_DISCORD, COMMUNITY_TWITTER } from '../../configs';

const Outers = ({ className }: { className?: string }) => {
  return (
    <span className={classNames(styles.outers, className)}>
      <a
        href={COMMUNITY_TWITTER}
        className={styles.outLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Twitter className={styles.linkIcon} />
      </a>
      <a
        href={COMMUNITY_DISCORD}
        className={styles.outLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Discord className={styles.linkIcon} />
      </a>
    </span>
  );
};

const Navigation = () => {
  const dispatch = useReduxDispatch();
  const { pathname } = useLocation();

  const appLaunched = useReduxSelector((state) => state.app.appLaunched);

  useEffect(() => {
    if (pathname !== '/') {
      dispatch(setAppLaunched(true));
    } else {
      dispatch(setAppLaunched(false));
    }
  }, [pathname, dispatch]);

  return (
    <div className={styles.Container}>
      <Link className={styles.home} to="/">
        <LogoWithFont className={styles.logo} />
      </Link>
      {appLaunched && <NavLinks path={pathname} />}
      <div className={styles.right}>
        <Outers />
        <div className={styles.buttonBox}>
          <ConnectButton />
        </div>
      </div>
    </div>
  );
};

export default Navigation;
