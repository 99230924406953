import { BN } from '@project-serum/anchor';
import BigNumber from 'bignumber.js';
import { MAX_LTV_RATIO } from '../configs';
import { CALUSD_DECIMAL, COLLATERAL_INDEX, COLLATERAL_TOKENS } from '../constants/token';
import {
  UserTransmuterParams,
  CollateralInfoParams,
  UserCollateralInfoParams,
} from '../types/interfaces';
import { getBaseLog } from './calculators';
import { CollateralTokens } from './tokens';

export function calculateAvailableBorrowing(collateral: string, debt: string): number {
  return +collateral * MAX_LTV_RATIO - +debt;
}

export function calculateAvailableWithdrawal(collateral: string, debt: string): number {
  return +collateral - +debt / MAX_LTV_RATIO;
}

const YEAR_DAYS = 365;

export function calculateMaturityDate(
  apy: string | number,
  deposit: string | number,
  loan: string | number
): { date: string; days: string } {
  if (
    Number.isNaN(+apy) ||
    Number.isNaN(+deposit) ||
    Number.isNaN(+loan) ||
    +apy === 0 ||
    +deposit === 0
  )
    return { date: '--', days: '0' };

  const maturity = getBaseLog(
    Number(apy) + 1,
    new BigNumber(Number(deposit) + Number(loan)).dividedBy(deposit).toNumber()
  ).toFixed(2);

  const iy = Math.floor(+maturity);
  const id = +maturity - iy;

  const now = new Date();
  const maturityDate = new Date(
    now.getFullYear() + iy,
    now.getMonth(),
    now.getDate() + YEAR_DAYS * id
  );

  return {
    date: new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }).format(maturityDate),
    days: Math.floor(+maturity * YEAR_DAYS).toString(),
  };
}

export function getDisplayingAPY(apy: number): string {
  if (Number.isNaN(+apy) || +apy === 0) return '0%';

  return `${apy}%`;
}

const ZERO = new BN(0);
const CAL_TOKEN_PRECISION = new BN(10).pow(new BN(CALUSD_DECIMAL));

export function convertDecimals(amount: BN, collateralDecimals: number) {
  const PRECISION = new BN(10).pow(new BN(Math.abs(collateralDecimals - CALUSD_DECIMAL)));

  if (collateralDecimals > CALUSD_DECIMAL) {
    return amount.mul(PRECISION);
  }
  return amount.div(PRECISION);
}

export function convertToLoanDecimals(amount: BN, collateralDecimals: number) {
  const PRECISION = new BN(10).pow(new BN(Math.abs(collateralDecimals - CALUSD_DECIMAL)));

  if (collateralDecimals < CALUSD_DECIMAL) {
    return amount.mul(PRECISION);
  }
  return amount.div(PRECISION);
}

export function calculateConvertible(
  vaultParams: CollateralInfoParams,
  userParams: UserTransmuterParams,
  timestamp: number,
  collateralDecimals: number
): BN {
  if (!vaultParams || !userParams) return ZERO;

  const {
    transmuterLastDistributionTime,
    transmutationPeriod,
    transmuterTotalSupplyCaltokens,
    transmuterTotalDividendPoints,
    transmuterBuffer,
  } = vaultParams;

  const { tokensInBucket, lastDividendPoints, depositedTokens } = userParams;

  const deltaTime = timestamp - transmuterLastDistributionTime.toNumber();

  let toDistribute = ZERO;

  if (deltaTime > transmutationPeriod.toNumber()) {
    toDistribute = transmuterBuffer;
  } else {
    if (transmuterBuffer.mul(new BN(deltaTime)).gt(transmutationPeriod)) {
      toDistribute = transmuterBuffer.mul(new BN(deltaTime)).div(transmutationPeriod);
    }
  }

  if (transmuterTotalSupplyCaltokens.gt(ZERO) && toDistribute.gt(ZERO)) {
    const toDistributeLoanDeciamls = convertToLoanDecimals(toDistribute, collateralDecimals);

    const newTransmuterTotalDividendPoints = transmuterTotalDividendPoints.add(
      toDistributeLoanDeciamls.mul(CAL_TOKEN_PRECISION).div(transmuterTotalSupplyCaltokens)
    );

    const newDividendPoints = newTransmuterTotalDividendPoints.sub(lastDividendPoints);

    const owing = depositedTokens.mul(newDividendPoints).div(CAL_TOKEN_PRECISION);

    if (owing.gt(ZERO)) {
      const totalConvertible = tokensInBucket.add(owing);

      if (depositedTokens.lt(totalConvertible)) {
        return convertDecimals(depositedTokens, collateralDecimals);
      }
      return convertDecimals(totalConvertible, collateralDecimals);
    }
  }

  return ZERO;
}

export function calculateDebt(
  totalDebt: BN,
  vaultCollateralInfo: CollateralInfoParams[],
  userCollateralInfo: UserCollateralInfoParams[]
): BN {
  if (!totalDebt || !vaultCollateralInfo.length || !userCollateralInfo.length) return ZERO;

  const totalEarned = COLLATERAL_TOKENS.reduce((sum, token) => {
    const index = COLLATERAL_INDEX[token];
    const collateralMintDecimals = CollateralTokens[token].mintDecimals;

    const vaultInfo = vaultCollateralInfo.find(({ collateralIndex }) => collateralIndex === index);
    const userInfo = userCollateralInfo.find(({ collateralIndex }) => collateralIndex === index);

    if (vaultInfo && userInfo) {
      const { yieldWeight } = vaultInfo;
      const { lastYieldWeight, totalDeposit } = userInfo;

      let earned = ZERO;

      if (yieldWeight.gte(lastYieldWeight)) {
        earned = yieldWeight.sub(lastYieldWeight).mul(totalDeposit).div(CAL_TOKEN_PRECISION);
      }

      let adjustmentLoanDecimals = ZERO;

      if (collateralMintDecimals > CALUSD_DECIMAL) {
        const adjustedment = new BN(10).pow(new BN(collateralMintDecimals - CALUSD_DECIMAL));

        adjustmentLoanDecimals = earned.div(adjustedment);
      } else {
        const adjustedment = new BN(10).pow(new BN(CALUSD_DECIMAL - collateralMintDecimals));

        adjustmentLoanDecimals = earned.mul(adjustedment);
      }

      sum.iadd(adjustmentLoanDecimals);
    }

    return sum;
  }, ZERO);

  if (totalEarned.gt(totalDebt)) {
    return ZERO;
  } else {
    return totalDebt.sub(totalEarned);
  }
}
