import { FC } from 'react';

const HomeIcon: FC<{ active: boolean }> = ({ active }) => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none">
      <path
        d="M19 18.9821C19 19.2521 18.8946 19.511 18.7071 19.7019C18.5196 19.8928 18.2652 20 18 20H4
          C3.73478 20 3.48043 19.8928 3.29289 19.7019C3.10536 19.511 3 19.2521 3 18.9821V9.82103H0L10.327 0.265017
          C10.5111 0.0944967 10.7511 0 11 0C11.2489 0 11.4889 0.0944967 11.673 0.265017L22 9.82103H19V18.9821Z
          M11 13.8926C11.663 13.8926 12.2989 13.6245 12.7678 13.1473C13.2366 12.67 13.5 12.0228 13.5 11.3479
          C13.5 10.673 13.2366 10.0257 12.7678 9.54847C12.2989 9.07124 11.663 8.80313 11 8.80313
          C10.337 8.80313 9.70107 9.07124 9.23223 9.54847C8.76339 10.0257 8.5 10.673 8.5 11.3479
          C8.5 12.0228 8.76339 12.67 9.23223 13.1473C9.70107 13.6245 10.337 13.8926 11 13.8926Z"
        fill={active ? 'url(#home_linear_paint)' : '#7D657E'}
      />
      <defs>
        <linearGradient
          id="home_linear_paint"
          x1="18.6473"
          y1="-2.85715"
          x2="-4.77326"
          y2="-0.551416"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9774E2" />
          <stop offset="0.359375" stopColor="#CF6A80" />
          <stop offset="1" stopColor="#EF6D36" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HomeIcon;
