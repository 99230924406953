import * as anchor from '@project-serum/anchor';
import { RpcAccountInfo } from '../types/interfaces';

export function getAccountState(program: anchor.Program, account: string, data: RpcAccountInfo) {
  if (!data || !Array.isArray(data.data)) return null;

  return program.coder.accounts.decode(
    account,
    Buffer.from(data.data[0], data.data[1] as BufferEncoding)
  );
}
