import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store/store';
import WalletProvider from './components/Wallet/Wallet';

const Root = () => {
  return (
    <Provider store={store}>
      <WalletProvider>
        <Router>
          <App />
        </Router>
      </WalletProvider>
    </Provider>
  );
};

export default Root;
