import React, { Suspense } from 'react';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import { useConnection, useAnchorWallet } from '@solana/wallet-adapter-react';
import initiateAppData from '../hooks/useInitiateHooks';
import { HIDE_CONVERT, SHOW_TOOLS } from '../configs';
import DotsLoading from '../components/Loading/DotsLoading';

const Landing = React.lazy(() => import('./landing/Landing'));
const Vault = React.lazy(() => import('./vault/VaultContainer'));
const Convert = React.lazy(() => import('./convert/ConvertContainer'));
const Social = React.lazy(() => import('./social/SocialContainer'));
const Tools = React.lazy(() => import('./tools/Tools'));

const Routes = () => {
  const { connection } = useConnection();
  const wallet = useAnchorWallet();

  initiateAppData(connection, wallet as any);

  return (
    <Suspense fallback={<DotsLoading />}>
      <Switch>
        <Route path="/" element={<Landing />} />
        <Route path="/vault" element={<Vault />} />
        {!HIDE_CONVERT && <Route path="/convert" element={<Convert />} />}
        <Route path="/social" element={<Social />} />
        {SHOW_TOOLS && <Route path="/tools" element={<Tools />} />}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
