import { Wallet } from '@project-serum/common';
import { Keypair, PublicKey } from '@solana/web3.js';
import { CollateralTypes, VaultTypes } from '../types/unions';
import { CollateralTokens, VaultTokens } from './tokens';
import { findAssociatedTokenAddress } from './associated-token-account';
import {
  getVaultPDA,
  getLoanMintPDA,
  getUserTransmuterPDA,
  getUserVaultStatePDA,
  getTestTulipVault,
  getTestTulipVaultPDA,
  getTestTulipShareMintPDA,
  getTestTulipVaultUnderlyingAccount,
} from './pda-addresses';
import { LoanAccounts, TulipVaultAccounts, VaultAccounts } from '../types/interfaces';
import { TULIP_ACCOUNTS } from '../constants/tulip-accounts';
import { IS_MAINNET, WITHDRAW_SHARE_MINT } from '../configs';

export class LoanMint {
  loanMintAddress: PublicKey;
  loanMintDecimals: number;
  vaultAddress: PublicKey;

  constructor(public wallet: Wallet, type: VaultTypes) {
    this.loanMintAddress = VaultTokens[type].mint;
    this.vaultAddress = VaultTokens[type].vault;
    this.loanMintDecimals = VaultTokens[type].decimals;
  }

  async getAccounts(): Promise<LoanAccounts> {
    const [loanMintPDA] = await getLoanMintPDA(this.loanMintAddress);
    const [vaultPDA] = await getVaultPDA(this.vaultAddress);

    const [userVaultStateAddress, userVaultStateNonce] = await getUserVaultStatePDA(
      this.wallet.publicKey,
      this.vaultAddress
    );

    const userLoanTokenAccount = await findAssociatedTokenAddress(
      this.wallet.publicKey,
      this.loanMintAddress
    );

    return {
      loanMintAddress: this.loanMintAddress,
      loanMintDecimals: this.loanMintDecimals,
      vaultAddress: this.vaultAddress,
      loanMintPDA,
      vaultPDA,
      userVaultStateAddress,
      userVaultStateNonce,
      userLoanTokenAccount,
    };
  }
}

export class CollateralMint {
  collateralTokenAddress: PublicKey;
  vaultCollateralTokenAccount: PublicKey;
  collateralTokenDecimals: number;
  collateralIndex: number;

  constructor(public wallet: Wallet, public token: CollateralTypes) {
    this.collateralTokenAddress = CollateralTokens[token].mintAddress;
    this.vaultCollateralTokenAccount = CollateralTokens[token].vaultTokenAccount;
    this.collateralTokenDecimals = CollateralTokens[token].mintDecimals;
    this.collateralIndex = CollateralTokens[token].index;
  }

  async getAccounts(): Promise<VaultAccounts> {
    const loanMint = new LoanMint(this.wallet, CollateralTokens[this.token].vault);
    const accounts = await loanMint.getAccounts();

    const userCollateralTokenAccount = await findAssociatedTokenAddress(
      this.wallet.publicKey,
      this.collateralTokenAddress
    );

    const [userTransmuterStateAddress, userTransmuterStateNonce] = await getUserTransmuterPDA(
      this.wallet.publicKey,
      accounts.vaultAddress,
      this.collateralIndex
    );

    const userTransmuterTokenAccount = await findAssociatedTokenAddress(
      userTransmuterStateAddress,
      accounts.loanMintAddress
    );

    return {
      ...accounts,
      collateralTokenAddress: this.collateralTokenAddress,
      vaultCollateralTokenAccount: this.vaultCollateralTokenAccount,
      collateralTokenDecimals: this.collateralTokenDecimals,
      collateralIndex: this.collateralIndex,
      userCollateralTokenAccount,
      userTransmuterStateAddress,
      userTransmuterStateNonce,
      userTransmuterTokenAccount,
    };
  }
}

export class TestTulipVault {
  static async getAccounts(token: CollateralTypes): Promise<TulipVaultAccounts> {
    let accounts = TULIP_ACCOUNTS[token].accounts as TulipVaultAccounts;

    if (!IS_MAINNET) {
      const [multiDeposit] = await getTestTulipVault(token);
      const [multiDepositPda] = await getTestTulipVaultPDA(multiDeposit);
      const [multiSharesMint] = await getTestTulipShareMintPDA(multiDeposit);

      const withdrawVault = new Keypair();
      const [withdrawVaultPda] = await getTestTulipVaultPDA(withdrawVault.publicKey);
      const [underlyingWithdrawQueue] = await getTestTulipVaultUnderlyingAccount(multiDeposit);

      accounts = {
        ...accounts,
        multiDeposit,
        multiDepositPda,
        multiSharesMint,
        withdrawVault: withdrawVault.publicKey,
        withdrawVaultPda,
        underlyingWithdrawQueue,
        withdrawSharesMint: WITHDRAW_SHARE_MINT,
      };
    }

    accounts.withdrawBurningSharesTokenAccount = await findAssociatedTokenAddress(
      accounts.multiDepositPda,
      accounts.withdrawSharesMint
    );

    return accounts;
  }
}
