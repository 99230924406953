export const CALCULATOR_DEFAULT_DECIMAL = 2;

export const CALUSD_DECIMAL = 9;
export const CALUSD_SYMBOL = 'calUSD';

export const LOAN_TOKENS = [CALUSD_SYMBOL] as const;

// export const COLLATERAL_TOKENS = ['USDC', 'USDT'] as const;
export const COLLATERAL_TOKENS = ['USDC'] as const;

// export const ALL_VAULT_TOKENS = [CALUSD_SYMBOL, 'USDC', 'USDT'];
export const ALL_VAULT_TOKENS = [CALUSD_SYMBOL, 'USDC'];

export const COLLATERAL_INDEX = {
  USDC: 1,
  USDT: 2,
};
