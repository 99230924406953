import { FC } from 'react';

const ConvertIcon: FC<{ active: boolean }> = ({ active }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10
          C20 15.523 15.523 20 10 20ZM10 7H6V9H15L10 4V7ZM5 11L10 16V13H14V11H5Z"
        fill={active ? 'url(#convert_linear_paint)' : '#7D657E'}
      />
      <defs>
        <linearGradient
          id="convert_linear_paint"
          x1="18.6473"
          y1="-2.85715"
          x2="-4.77326"
          y2="-0.551416"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9774E2" />
          <stop offset="0.359375" stopColor="#CF6A80" />
          <stop offset="1" stopColor="#EF6D36" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ConvertIcon;
