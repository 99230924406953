import { COINGECKO_SERVICE, TULIP_SERVICE } from '../configs';
import { COLLATERAL_TOKENS } from '../constants/token';
import coins from '../data/coins.json';
import { CollateralTypes } from '../types/unions';
import { calculateAPYFromAPR } from './calculators';

type Method = 'GET' | 'POST';

export async function fetchData(url: string, method: Method, body?: object) {
  const res = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (res.ok) {
    const data = await res.json();

    return data;
  } else {
    throw new Error('Failed to get data!!!');
  }
}

export async function fetchPriceFromCoinGecko() {
  const activeCoins = coins.filter(({ symbol }) =>
    COLLATERAL_TOKENS.includes(symbol.toUpperCase() as CollateralTypes)
  );

  const ids = activeCoins.map(({ id }) => id);

  const queries = `ids=${ids.join(',')}&vs_currencies=usd`;

  const res = await fetchData(`${COINGECKO_SERVICE}/simple/price?${queries}`, 'GET');

  const prices: any = {};

  activeCoins.forEach(({ id, symbol }) => {
    prices[symbol.toUpperCase()] = res[id];
  });

  return prices;
}

interface YieldResponse {
  apr: number;
  farm_name: string;
  vault_address: string;
}

export async function fetchYeildsFromTulip() {
  const farmNames = COLLATERAL_TOKENS.map(
    (token) => `LENDING-MULTI_DEPOSIT-tag(${token.toLowerCase()}v1)`
  );

  const res = await fetchData(`${TULIP_SERVICE}/yield`, 'POST', {
    farm_names: farmNames,
  });

  const vaults: YieldResponse[] = res.vaults;

  const yeilds: any = {};

  farmNames.forEach((name, index) => {
    const farm = vaults.find(({ farm_name }) => farm_name === name);

    if (farm) {
      yeilds[COLLATERAL_TOKENS[index]] = calculateAPYFromAPR(farm.apr);
    }
  });

  return yeilds;
}
