import { RpcResponse } from '../types/interfaces';

const configurations = {
  getTokenAccountBalance: {
    commitment: 'recent',
  },
  getAccountInfo: {
    commitment: 'recent',
    encoding: 'jsonParsed',
  },
};

type RpcMethods = 'getTokenAccountBalance' | 'getAccountInfo';

export interface RequestData {
  method: RpcMethods;
  address: string;
}

export async function batchRpcRequest(
  endpoint: string,
  requests: RequestData[]
): Promise<RpcResponse[]> {
  const payload = requests.map(({ method, address }, index) => ({
    jsonrpc: '2.0',
    id: index,
    method,
    params: [address, configurations[method]],
  }));

  const res = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (res.ok) {
    const data = await res.json();

    return data;
  } else {
    throw new Error('Failed to fetch rpc request batched');
  }
}
