import { FC } from 'react';

const SocialIcon: FC<{ active: boolean }> = ({ active }) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none">
      <path
        d="M9.04762 11.9899V19H2.33842e-07C-0.000280579 17.8952 0.252357 16.805 0.738556 15.8129
          C1.22475 14.8208 1.93161 13.9532 2.80496 13.2765C3.67831 12.5998 4.69497 12.1321 5.77704 11.9091
          C6.85912 11.686 7.97788 11.7137 9.04762 11.9899ZM7.23809 10.8571C4.23881 10.8571 1.80952 8.42786 1.80952 5.42857
          C1.80952 2.42929 4.23881 0 7.23809 0C10.2374 0 12.6667 2.42929 12.6667 5.42857C12.6667 8.42786 10.2374 10.8571 7.23809 10.8571Z
          M12.6667 14.4762V11.7619H14.4762V14.4762H17.1905V16.2857H14.4762V19H12.6667V16.2857H9.95238V14.4762H12.6667Z"
        fill={active ? 'url(#social_linear_paint)' : '#7D657E'}
      />
      <defs>
        <linearGradient
          id="social_linear_paint"
          x1="18.6473"
          y1="-2.85715"
          x2="-4.77326"
          y2="-0.551416"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9774E2" />
          <stop offset="0.359375" stopColor="#CF6A80" />
          <stop offset="1" stopColor="#EF6D36" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SocialIcon;
