import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as anchor from '@project-serum/anchor';
import { batchRpcRequest, RequestData } from '../../utils/batch';
import { convertAmountToInteger } from '../../utils/calculators';
import { findAssociatedTokenAddress } from '../../utils/associated-token-account';
import { calculateConvertible, calculateDebt } from '../../utils/vault-details';
import { getUserTransmuterPDA } from '../../utils/pda-addresses';
import { CollateralTokens, VaultTokens } from '../../utils/tokens';
import { getAccountState } from '../../utils/vault-states';
import { CollateralMint, LoanMint } from '../../utils/accounts';
import { getVaultProgram } from '../../utils/programs';
import { timestampInSecond } from '../../utils/timestamp';
import {
  CALUSD_DECIMAL,
  COLLATERAL_TOKENS,
  COLLATERAL_INDEX,
  LOAN_TOKENS,
} from '../../constants/token';
import { VaultActionTypes } from '../../types/enums';
import { AllVaultTokens, CollateralTypes, VaultTypes } from '../../types/unions';
import {
  CollateralInfoParams,
  UserCollateralInfoParams,
  UserVaultParams,
  VaultMeta,
  VaultParams,
} from '../../types/interfaces';
import { CLUSTER_API_URL } from '../../configs';

export const loadGlobalVaultData = createAsyncThunk('vault/loadGlobalVaultData', async () => {
  const accounts = [];

  for (const key in VaultTokens) {
    const type = key as VaultTypes;

    if (LOAN_TOKENS.includes(type)) {
      const { vault } = VaultTokens[type];

      accounts.push({
        method: 'getAccountInfo',
        address: vault.toBase58(),
      });
    }
  }

  const responses = await batchRpcRequest(CLUSTER_API_URL, accounts as RequestData[]);

  const vaultCollateralInfo: CollateralInfoParams[] = [];

  const { program } = getVaultProgram(null as any, null as any);

  responses.forEach((data) => {
    const vaultState: VaultParams = getAccountState(program, 'Vault', data?.result?.value);

    if (vaultState?.collateralInfo) {
      vaultCollateralInfo.push(...vaultState.collateralInfo);
    }
  });

  const meta: any = {};

  for (const token of COLLATERAL_TOKENS) {
    const collateralInfo = vaultCollateralInfo.find(
      ({ collateralIndex }) => collateralIndex === COLLATERAL_INDEX[token]
    );

    const totalValueLocked = convertAmountToInteger(
      collateralInfo?.totalDeposited || '',
      CollateralTokens[token].mintDecimals
    );

    meta[token] = {
      totalValueLocked,
    };
  }

  return meta;
});

const LOAN_TOKEN_BALANCE_INDEX = 0;
const USER_VAULT_STATE_INDEX = 1;
const VAULT_STATE_INDEX = 2;

export const loadVaultBalances = createAsyncThunk(
  'vault/loadVaultBalances',
  async ({ provider, program }: { provider: anchor.Provider; program: anchor.Program }) => {
    const accounts = [];

    for (const key in VaultTokens) {
      const type = key as VaultTypes;

      if (LOAN_TOKENS.includes(type)) {
        const loanMint = new LoanMint(provider.wallet, type);
        const { userLoanTokenAccount, userVaultStateAddress, vaultAddress } =
          await loanMint.getAccounts();

        accounts.push(
          {
            method: 'getTokenAccountBalance',
            address: userLoanTokenAccount.toBase58(),
          },
          {
            method: 'getAccountInfo',
            address: userVaultStateAddress.toBase58(),
          },
          {
            method: 'getAccountInfo',
            address: vaultAddress.toBase58(),
          }
        );
      }
    }

    for (const token of COLLATERAL_TOKENS) {
      const collateralTokenAccount = await findAssociatedTokenAddress(
        provider.wallet.publicKey,
        CollateralTokens[token].mintAddress
      );

      const [userTransmuterStatePDA] = await getUserTransmuterPDA(
        provider.wallet.publicKey,
        CollateralTokens[token].vaultAddress,
        CollateralTokens[token].index
      );

      accounts.push(
        {
          method: 'getTokenAccountBalance',
          address: collateralTokenAccount.toBase58(),
        },
        {
          method: 'getAccountInfo',
          address: userTransmuterStatePDA.toBase58(),
        }
      );
    }

    const responses = await batchRpcRequest(CLUSTER_API_URL, accounts as RequestData[]);

    const calUSDBalance = responses[LOAN_TOKEN_BALANCE_INDEX]?.result?.value.uiAmountString || '';

    const userVaultState: UserVaultParams = getAccountState(
      program,
      'UserVaultState',
      responses[USER_VAULT_STATE_INDEX]?.result?.value
    );

    const vaultState: VaultParams = getAccountState(
      program,
      'Vault',
      responses[VAULT_STATE_INDEX]?.result?.value
    );

    const userVaultCollateralInfo: UserCollateralInfoParams[] = [];
    if (userVaultState?.userCollateralInfo) {
      userVaultCollateralInfo.push(...userVaultState.userCollateralInfo);
    }

    const vaultCollateralInfo: CollateralInfoParams[] = [];
    if (vaultState?.collateralInfo) {
      vaultCollateralInfo.push(...vaultState.collateralInfo);
    }

    const adjustedDedt = calculateDebt(
      userVaultState?.totalDebt,
      vaultCollateralInfo,
      userVaultCollateralInfo
    );
    const userTotalDebt = convertAmountToInteger(adjustedDedt, CALUSD_DECIMAL);

    const userTotalDeposit = new anchor.BN(0);

    const rest = responses.slice(3 * 1); // number 1 means that vault has 1 loan token for now
    const meta: any = {};

    COLLATERAL_TOKENS.forEach((key, index: number) => {
      const walletBalance =
        rest[index * COLLATERAL_TOKENS.length + 0]?.result?.value.uiAmountString || '';

      const collateralInfo = vaultCollateralInfo.find(
        ({ collateralIndex }) => collateralIndex === COLLATERAL_INDEX[key]
      );
      const totalValueLocked = convertAmountToInteger(
        collateralInfo?.totalDeposited || '',
        CollateralTokens[key].mintDecimals
      );

      const userCollateralInfo = userVaultCollateralInfo.find(
        ({ collateralIndex }) => collateralIndex === COLLATERAL_INDEX[key]
      );
      const collateralValue = convertAmountToInteger(
        userCollateralInfo?.totalDeposit || '',
        CollateralTokens[key].mintDecimals
      );
      userTotalDeposit.iaddn(+collateralValue);

      const userTransmuterState = getAccountState(
        program,
        'UserTransmuterState',
        rest[index * COLLATERAL_TOKENS.length + 1]?.result?.value
      );

      const stakedValue = convertAmountToInteger(
        userTransmuterState?.depositedTokens,
        CALUSD_DECIMAL
      );

      const convertedAmount = calculateConvertible(
        collateralInfo!,
        userTransmuterState,
        timestampInSecond(),
        CollateralTokens[key].mintDecimals
      );

      const convertibleValue = convertAmountToInteger(
        convertedAmount,
        CollateralTokens[key].mintDecimals
      );

      meta[key] = {
        walletBalance,
        collateralValue,
        totalValueLocked,
        stakedValue,
        convertibleValue,
      };
    });

    const data = {
      calUSDBalance,
      userTotalDebt,
      userTotalDeposit: userTotalDeposit.toString(),
      ...meta,
    };
    // console.log(':::::::::::::::', data);

    return data;
  }
);

export const loadBalancesByType = createAsyncThunk(
  'vault/loadBalancesByType',
  async ({
    provider,
    program,
    type,
  }: {
    provider: anchor.Provider;
    program: anchor.Program;
    type: CollateralTypes;
  }) => {
    const collMint = new CollateralMint(provider.wallet, type);
    const addrs = await collMint.getAccounts();

    const accounts = [
      {
        method: 'getTokenAccountBalance',
        address: addrs.userCollateralTokenAccount.toBase58(),
      },
      {
        method: 'getTokenAccountBalance',
        address: addrs.userLoanTokenAccount.toBase58(),
      },
      {
        method: 'getAccountInfo',
        address: addrs.vaultAddress.toBase58(),
      },
      {
        method: 'getAccountInfo',
        address: addrs.userTransmuterStateAddress.toBase58(),
      },
    ];

    const responses = await batchRpcRequest(CLUSTER_API_URL, accounts as RequestData[]);

    const walletBalance = responses[0]?.result?.value.uiAmountString || '';
    const calUSDBalance = responses[1]?.result?.value.uiAmountString || '';

    const vaultState: VaultParams = await getAccountState(
      program,
      'Vault',
      responses[2]?.result?.value
    );

    const userTransmuterState = await getAccountState(
      program,
      'UserTransmuterState',
      responses[3]?.result?.value
    );

    const vaultCollateralInfo: CollateralInfoParams[] = [];
    if (vaultState?.collateralInfo) {
      vaultCollateralInfo.push(...vaultState.collateralInfo);
    }

    const collateralInfo = vaultCollateralInfo.find(
      ({ collateralIndex }) => collateralIndex === addrs.collateralIndex
    );

    const stakedValue = convertAmountToInteger(
      userTransmuterState?.depositedTokens,
      CALUSD_DECIMAL
    );

    const convertedAmount = calculateConvertible(
      collateralInfo!,
      userTransmuterState,
      timestampInSecond(),
      addrs.collateralTokenDecimals
    );

    const convertibleValue = convertAmountToInteger(convertedAmount, addrs.collateralTokenDecimals);

    return {
      calUSDBalance,
      type,
      meta: {
        walletBalance,
        stakedValue,
        convertibleValue,
      },
    };
  }
);

type VaultMetaType = {
  [key in CollateralTypes]: VaultMeta;
};

interface VaultState {
  calUSDBalance: string;
  userTotalDebt: string;
  userTotalDeposit: string;
  metaList: VaultMetaType;
  selectedVault: CollateralTypes;
  action: {
    type: VaultActionTypes;
    token: AllVaultTokens | 'None';
    limit: string;
  };
}

export const initialState: VaultState = {
  calUSDBalance: '',
  userTotalDebt: '',
  userTotalDeposit: '',
  metaList: COLLATERAL_TOKENS.reduce((meta, token) => {
    meta[token] = {
      walletBalance: '',
      collateralValue: '',
      totalValueLocked: '',
      stakedValue: '',
      convertibleValue: '',
    };
    return meta;
  }, {} as VaultMetaType),
  selectedVault: 'USDC',
  action: {
    type: VaultActionTypes.NONE,
    token: 'None',
    limit: '',
  },
};

const vaultSlice = createSlice({
  name: 'vault',
  initialState,
  reducers: {
    setSelectedVault: (state, action) => {
      state.selectedVault = action.payload;
    },
    setAction: (state, action) => {
      state.action = {
        ...state.action,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadGlobalVaultData.fulfilled, (state, action) => {
        COLLATERAL_TOKENS.forEach((type) => {
          state.metaList[type] = {
            ...state.metaList[type],
            ...action.payload[type],
          };
        });
      })
      .addCase(loadGlobalVaultData.rejected, (state, { error }) => {
        console.log(state, error);
      })
      .addCase(loadVaultBalances.fulfilled, (state, action) => {
        state.calUSDBalance = action.payload.calUSDBalance;
        state.userTotalDebt = action.payload.userTotalDebt;
        state.userTotalDeposit = action.payload.userTotalDeposit;

        COLLATERAL_TOKENS.forEach((type) => {
          state.metaList[type] = {
            ...state.metaList[type],
            ...action.payload[type],
          };
        });
      })
      .addCase(loadVaultBalances.rejected, (state, { error }) => {
        console.log(state, error);
      })
      .addCase(loadBalancesByType.fulfilled, (state, action) => {
        state.calUSDBalance = action.payload.calUSDBalance;

        const type = action.payload.type;
        state.metaList[type] = {
          ...state.metaList[type],
          ...action.payload?.meta,
        };
      })
      .addCase(loadBalancesByType.rejected, (state, { error }) => {
        console.log(state, error);
      });
  },
});

export const { setSelectedVault, setAction } = vaultSlice.actions;

export default vaultSlice.reducer;
