import { PublicKey } from '@solana/web3.js';

// export const TULIP_ACCOUNTS = {
//   USDC: {
//     accounts: {
//       multiDeposit: new PublicKey('3wPiV9inTGexMZjp6x5Amqwp2sRNtpSheG8Hbv2rgq8W'),
//       multiDepositPda: new PublicKey('14fdy6YXbhDgnVQz4VcgSGgUcZ35eE48SKDrfqF87NUP'),
//       withdrawVault: new PublicKey('8KLrrsnUv3DjC9Q89xSQDVdiGLZHUEUuyPedfHrtuVRr'),
//       withdrawVaultPda: new PublicKey('mrT9Q45iuC2HLYxpceaQFjzcAgd6Trks7bXAGbKYpwL'),
//       platformInformation: new PublicKey('4QVuedVSCMLA3eQ643czUy95uQFxXKAcCMJ1ChpkVA2B'),
//       platformConfigData: new PublicKey('7XTtoiHfkYzjLDxKDMoaVYncmdBW1yLfphmisSbBrnuZ'),
//       lendingProgram: new PublicKey('4bcFeLv4nydFrsZqV5CgwCVrPhkQKsXtzfy2KyMz7ozM'),
//       underlyingWithdrawQueue: new PublicKey('HLVcpKPkBJJJGTHTSaZcAixDppy4R65x1is3k8Q7qZpj'),
//       multiSharesMint: new PublicKey('Cvvh8nsKZet59nsDDo3orMa3rZnPWQhpgrMCVcRDRgip'),
//       withdrawVaultUnderlyingDepositQueue: new PublicKey(
//         '8eDHmS15CWd8d88uckg6oKxrfwijZVudZsDgdtgGqS49'
//       ),
//       withdrawSharesMint: new PublicKey('D2PLcwGR1wsXUPhb1BHysSVEsHVVCQ129qGpgXXaTNR1'),
//     },
//     /* !!!important: The accunts order must not be changed. */
//     remainingAccounts: [
//       // withdrawCollateralTokenAccount
//       {
//         pubkey: new PublicKey('2U6kk4iTVqeypBydVPKA8mLTLAQEBfWf4KYfmkcvomPE'),
//         isSigner: false,
//         isWritable: true,
//       },
//       // withdrawLendingReserve
//       {
//         pubkey: new PublicKey('FTkSmGsJ3ZqDSHdcnY7ejN1pWV3Ej7i88MYpZyyaqgGt'),
//         isSigner: false,
//         isWritable: true,
//       },
//       // withdrawReserveLiquiditySupplyAccount
//       {
//         pubkey: new PublicKey('64QJd6MYXUjCBvCaZKaqxiKmaMkPUdNonE1KuY1YoGGb'),
//         isSigner: false,
//         isWritable: true,
//       },
//       // withdrawCollateralMint
//       {
//         pubkey: new PublicKey('Amig8TisuLpzun8XyGfC5HJHHGUQEscjLgoTWsCCKihg'),
//         isSigner: false,
//         isWritable: true,
//       },
//       // withdrawLendingMarketAccount
//       {
//         pubkey: new PublicKey('D1cqtVThyebK9KXKGXrCEuiqaNf5L4UfM1vHgCqiJxym'),
//         isSigner: false,
//         isWritable: false,
//       },
//       // withdrawLendingMarketAuthority
//       {
//         pubkey: new PublicKey('8gEGZbUfVE1poBq71VHKX9LU7ca4x8wTUyZgcbyQe51s'),
//         isSigner: false,
//         isWritable: false,
//       },
//       // withdrawPythPriceAccount
//       {
//         pubkey: new PublicKey('ExzpbWgczTgd8J58BrnESndmzBkRVfc6PhFjSGiQXgAB'),
//         isSigner: false,
//         isWritable: false,
//       },
//     ],
//   },
// };

export const TULIP_ACCOUNTS = {
  USDC: {
    accounts: {
      multiDeposit: new PublicKey('3wPiV9inTGexMZjp6x5Amqwp2sRNtpSheG8Hbv2rgq8W'),
      multiDepositPda: new PublicKey('14fdy6YXbhDgnVQz4VcgSGgUcZ35eE48SKDrfqF87NUP'),
      withdrawVault: new PublicKey('85JXjDiyianDpvz8y8efkRyFsxpnSJJpmyxrJ7bncKHM'),
      withdrawVaultPda: new PublicKey('963HGaUjwGqvqwwqwJZayUXvCC21AAqZU5SLw1kU4gVc'),
      platformInformation: new PublicKey('GRL5rtnvzCfQRdKJXkG2A8LvDSNXkbxENEnF4SwJ3pTF'),
      platformConfigData: new PublicKey('6AzcPNmNWomkdMRgcZJPVAs4zF1jev9wqxzzzxVzDDsi'),
      lendingProgram: new PublicKey('So1endDq2YkqhipRh3WViPa8hdiSpxWy6z3Z6tMCpAo'),
      underlyingWithdrawQueue: new PublicKey('HLVcpKPkBJJJGTHTSaZcAixDppy4R65x1is3k8Q7qZpj'),
      multiSharesMint: new PublicKey('Cvvh8nsKZet59nsDDo3orMa3rZnPWQhpgrMCVcRDRgip'),
      withdrawVaultUnderlyingDepositQueue: new PublicKey(
        '2Li9Q5Vx9BEnFTGJTLWc5pVqerYGjhgyGYzSAA2WhYKQ'
      ),
      withdrawSharesMint: new PublicKey('CS8cJicaSpphTTboMJD1UeNpU7vpkZc86vKrtqzRVnG5'),
    },
    /* !!!important: The accunts order must not be changed. */
    remainingAccounts: [
      // withdrawCollateralTokenAccount
      {
        pubkey: new PublicKey('6EaiG2gRVu9u7QzVmX59AWLSmiaEYvMrKWQfPMCgNxsZ'),
        isSigner: false,
        isWritable: true,
      },
      // withdrawLendingReserve
      {
        pubkey: new PublicKey('BgxfHJDzm44T7XG68MYKx7YisTjZu73tVovyZSjJMpmw'),
        isSigner: false,
        isWritable: true,
      },
      // withdrawReserveLiquiditySupplyAccount
      {
        pubkey: new PublicKey('8SheGtsopRUDzdiD6v6BR9a6bqZ9QwywYQY99Fp5meNf'),
        isSigner: false,
        isWritable: true,
      },
      // withdrawCollateralMint
      {
        pubkey: new PublicKey('993dVFL2uXWYeoXuEBFXR4BijeXdTv4s6BzsCjJZuwqk'),
        isSigner: false,
        isWritable: true,
      },
      // withdrawLendingMarketAccount
      {
        pubkey: new PublicKey('4UpD2fh7xH3VP9QQaXtsS1YY3bxzWhtfpks7FatyKvdY'),
        isSigner: false,
        isWritable: false,
      },
      // withdrawLendingMarketAuthority
      {
        pubkey: new PublicKey('DdZR6zRFiUt4S5mg7AV1uKB2z1f1WzcNYCaTEEWPAuby'),
        isSigner: false,
        isWritable: false,
      },
      // withdrawPythPriceAccount
      {
        pubkey: new PublicKey('Gnt27xtC473ZT2Mw5u8wZ68Z3gULkSTb5DuxJy7eJotD'),
        isSigner: false,
        isWritable: false,
      },
      // withdrawSwitchboardPriceAccount
      {
        pubkey: new PublicKey('CZx29wKMUxaJDq6aLVQTdViPL754tTR64NAgQBUGxxHb'),
        isSigner: false,
        isWritable: false,
      },
    ],
  },
};
