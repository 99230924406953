import { createSlice } from '@reduxjs/toolkit';
import * as anchor from '@project-serum/anchor';

interface AppState {
  appLaunched: boolean;
  connection: {
    provider: anchor.Provider;
    program: anchor.Program;
  };
}

const initialState: AppState = {
  appLaunched: false,
  connection: {
    provider: null as any,
    program: null as any,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLaunched: (state, action) => {
      state.appLaunched = action.payload;
    },
    setConnection: (state, action) => {
      state.connection = action.payload;
    },
  },
});

export const { setAppLaunched, setConnection } = appSlice.actions;

export default appSlice.reducer;
