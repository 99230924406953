import { PublicKey, TransactionInstruction, SYSVAR_RENT_PUBKEY } from '@solana/web3.js';

export const TOKEN_PROGRAM_PUBLIC_KEY = new PublicKey(
  'TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'
);
export const ASSOCIATED_TOKEN_PROGRAM_PUBLIC_KEY = new PublicKey(
  'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL'
);

export async function findAssociatedTokenAddress(
  walletAddress: PublicKey,
  tokenMintAddress: PublicKey
) {
  return (
    await PublicKey.findProgramAddress(
      [walletAddress.toBuffer(), TOKEN_PROGRAM_PUBLIC_KEY.toBuffer(), tokenMintAddress.toBuffer()],
      ASSOCIATED_TOKEN_PROGRAM_PUBLIC_KEY
    )
  )[0];
}

async function createAssociatedTokenAccountIx(
  fundingAddress: PublicKey,
  walletAddress: PublicKey,
  splTokenMintAddress: PublicKey
): Promise<{ ix: TransactionInstruction; associatedTokenAddress: PublicKey }> {
  const associatedTokenAddress = await findAssociatedTokenAddress(
    walletAddress,
    splTokenMintAddress
  );
  const systemProgramId = new PublicKey('11111111111111111111111111111111');
  const keys = [
    {
      pubkey: fundingAddress,
      isSigner: true,
      isWritable: true,
    },
    {
      pubkey: associatedTokenAddress,
      isSigner: false,
      isWritable: true,
    },
    {
      pubkey: walletAddress,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: splTokenMintAddress,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: systemProgramId,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: TOKEN_PROGRAM_PUBLIC_KEY,
      isSigner: false,
      isWritable: false,
    },
    {
      pubkey: SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];
  const ix = new TransactionInstruction({
    keys,
    programId: ASSOCIATED_TOKEN_PROGRAM_PUBLIC_KEY,
    data: Buffer.from([]),
  });
  return {
    ix,
    associatedTokenAddress,
  };
}

interface CreateAssociatedTokenAccount {
  fundingWalletAddress: PublicKey;
  userWalletAddress: PublicKey;
  splTokenMintAddress: PublicKey;
}

export async function createAssociatedTokenAccount({
  fundingWalletAddress,
  userWalletAddress,
  splTokenMintAddress,
}: CreateAssociatedTokenAccount): Promise<{
  ix: TransactionInstruction;
  associatedTokenAddress: PublicKey;
}> {
  const { ix, associatedTokenAddress } = await createAssociatedTokenAccountIx(
    fundingWalletAddress,
    userWalletAddress,
    splTokenMintAddress
  );

  return {
    ix,
    associatedTokenAddress,
  };
}
