import { FC, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useReduxDispatch, useReduxSelector } from '../../hooks/useReduxHooks';
import { setTrxnStatus } from '../../store/slices/messageSlice';
import {
  TRXN_FAILURE_GENERIC,
  TRXN_PENDING_GENERIC,
  TRXN_SUCCESS_GENERIC,
} from '../../constants/messages';
import { ActionStatus } from '../../types/enums';
import styles from './TrxnMessage.module.scss';

const TrxnMessage: FC = () => {
  const dispatch = useReduxDispatch();
  const { status, message } = useReduxSelector((state) => state.message.trxn);

  const displayMessage = useMemo(() => {
    let msg = '';

    if (status === ActionStatus.SUCCESS) {
      msg = TRXN_SUCCESS_GENERIC;
    } else if (status === ActionStatus.FAILED) {
      msg = TRXN_FAILURE_GENERIC;
    } else if (status === ActionStatus.CONFIRMING) {
      msg = TRXN_PENDING_GENERIC;
    }

    return message || msg;
  }, [status, message]);

  const img = useMemo(() => {
    return (
      (
        {
          [ActionStatus.SUCCESS]: './images/logo-happy.png',
          [ActionStatus.FAILED]: './images/logo-sad.png',
        } as any
      )[status] || './images/logo.png'
    );
  }, [status]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (status === ActionStatus.SUCCESS || status === ActionStatus.FAILED) {
      timer = setTimeout(() => {
        dispatch(setTrxnStatus({ status: ActionStatus.NORMAL }));
      }, 2000);
    }

    return () => clearTimeout(timer);
  }, [status, dispatch]);

  if (!displayMessage) return <></>;

  return (
    <div className={styles.wrapBox}>
      <div
        className={classNames(styles.component, {
          [styles.component_success]: status === ActionStatus.SUCCESS,
          [styles.component_failed]: status === ActionStatus.FAILED,
          [styles.component_pending]: status === ActionStatus.CONFIRMING,
        })}
      >
        <div className={styles.inner}>
          <img src={img} className={styles.img} alt="" />
          <div className={styles.content}>{displayMessage}</div>
        </div>
      </div>
    </div>
  );
};

export default TrxnMessage;
