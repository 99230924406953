import * as anchor from '@project-serum/anchor';
import * as splToken from '@solana/spl-token';
import BigNumber from 'bignumber.js';
import { CALCULATOR_DEFAULT_DECIMAL } from '../constants/token';

export function convertAmountToUint(amount: string | number, decimals: number): anchor.BN {
  return new anchor.BN(new BigNumber(amount || '0').multipliedBy(10 ** decimals).toFixed(0));
}

export function convertAmountToInteger(
  amount: anchor.BN | splToken.u64 | string | number,
  decimals: number
): string {
  if (Number.isNaN(+amount) || +amount === 0) return '0';

  const value: BigNumber = new BigNumber(amount.toString());

  return value.dividedBy(10 ** decimals).toString();
}

export function calculateAmountByPercentage(amount: string | number, percent: number): string {
  if (Number.isNaN(+amount) || +amount === 0) return '0';

  if (percent === 1) return amount.toString();

  const value: BigNumber = new BigNumber(amount.toString());

  return value.multipliedBy(percent).toFixed(CALCULATOR_DEFAULT_DECIMAL);
}

export function getBaseLog(x: number, y: number): BigNumber {
  return new BigNumber(Math.log(y)).dividedBy(new BigNumber(Math.log(x)));
}

export function calculateAPYFromAPR(apr: number | string): number {
  if (Number.isNaN(+apr) || +apr === 0) return 0;

  const value = new BigNumber(apr);

  const r = value.dividedBy(365).dividedBy(100);

  const p = r.plus(1).pow(365);

  return Number(p.minus(1).multipliedBy(100).toFixed(2));
}
