import { createSlice } from '@reduxjs/toolkit';
import { ActionStatus } from '../../types/enums';

interface MessageState {
  trxn: {
    status: ActionStatus;
    message: string;
  };
}

const initialState: MessageState = {
  trxn: {
    status: ActionStatus.NORMAL,
    message: '',
  },
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setTrxnStatus: (state, action) => {
      state.trxn = {
        ...state.trxn,
        ...action.payload,
      };
    },
  },
});

export const { setTrxnStatus } = messageSlice.actions;

export default messageSlice.reducer;
