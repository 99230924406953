import { Link } from 'react-router-dom';
import classNames from 'classnames';
import HomeIcon from './HomeIcon';
import VaultIcon from './VaultIcon';
import ConvertIcon from './ConvertIcon';
import SocialIcon from './SocialIcon';
import { HIDE_CONVERT } from '../../configs';
import styles from './NavLinks.module.scss';

const ROUTES = [
  {
    link: '/',
    label: 'Home',
    Icon: HomeIcon,
  },
  {
    link: '/vault',
    label: 'Vault',
    Icon: VaultIcon,
  },
  {
    link: '/social',
    label: 'Social',
    Icon: SocialIcon,
  },
];

if (!HIDE_CONVERT) {
  ROUTES.splice(2, 0, {
    link: '/convert',
    label: 'Convert',
    Icon: ConvertIcon,
  });
}

const NavLinks = ({ path }: { path: string }) => {
  return (
    <div className={styles.links}>
      {ROUTES.map(({ link, label, Icon }) => {
        return (
          <Link
            key={label}
            className={classNames(styles.nav, {
              [styles.nav_hiden]: link === '/social',
            })}
            to={link}
          >
            <span className={styles.icon}>
              <Icon active={link === path} />
            </span>
            <span className={classNames(styles.text, { [styles.text_active]: link === path })}>
              {label}
            </span>
          </Link>
        );
      })}
    </div>
  );
};

export default NavLinks;
