import { useLocation } from 'react-router-dom';
import Navigation from './components/Navigation/Navigation';
import TrxnMessage from './components/Message/TrxnMessage';
import Routes from './pages/Routes';
import styles from './App.module.scss';

function App() {
  const { pathname } = useLocation();

  return (
    <div className={styles.AppContainer}>
      <Navigation />
      <div className={pathname === '/' ? '' : styles.AppBody}>
        <TrxnMessage />
        <Routes />
      </div>
    </div>
  );
}

export default App;
