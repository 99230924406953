export default class AsyncPolling {
  private interval: NodeJS.Timeout;
  private polling = false;

  constructor(asyncFunc: () => void, delay: number) {
    asyncFunc();

    this.interval = setInterval(() => {
      if (this.polling) {
        asyncFunc();
      }
    }, delay);
  }

  startPolling() {
    this.polling = true;
  }

  stopPolling() {
    this.polling = false;
  }

  terminatePolling() {
    this.polling = false;
    clearInterval(this.interval);
  }
}
