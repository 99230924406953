import { createSlice } from '@reduxjs/toolkit';
import { ModalTypes } from '../../types/enums';

interface ModalState {
  modal: ModalTypes;
}

const initialState: ModalState = {
  modal: ModalTypes.NONE,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.modal = action.payload;
    },
    closeModal: (state) => {
      state.modal = ModalTypes.NONE;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
