import * as anchor from '@project-serum/anchor';
import * as serumCmn from '@project-serum/common';
import { Connection } from '@solana/web3.js';
import VaultIdl from '../idl/vault.json';
import TestIdl from '../idl/test_vault.json';
import { VAULT_PROGRAM_ID, VAULT_TULIP_PROGRAM_ID } from '../configs';

export type ProgramKind = 'Vault' | 'Tulip';

function getProgramId(kind: ProgramKind) {
  return {
    Vault: VAULT_PROGRAM_ID,
    Tulip: VAULT_TULIP_PROGRAM_ID,
  }[kind];
}

function getIDL(kind: ProgramKind) {
  return {
    Vault: VaultIdl,
    Tulip: TestIdl,
  }[kind] as anchor.Idl;
}

export class CalProgram {
  provider: anchor.Provider;
  program: anchor.Program;

  constructor(connection: Connection, wallet: serumCmn.Wallet, type: ProgramKind) {
    this.provider = new anchor.Provider(connection, wallet, anchor.Provider.defaultOptions());

    this.program = new anchor.Program(getIDL(type), getProgramId(type), this.provider);
  }
}

export function getVaultProgram(connection: Connection, wallet: serumCmn.Wallet) {
  return new CalProgram(connection, wallet, 'Vault');
}

export function getTestProgram(connection: Connection, wallet: serumCmn.Wallet) {
  return new CalProgram(connection, wallet, 'Tulip');
}
